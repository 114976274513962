
.scannedPlateBg {
    width: 600px;
    height: 250px;
    /* background: transparent #C6BCBC 0% 0% no-repeat padding-box; */
    background: #C6BCBC;
    border: 1px solid #C6BCBC;
    border-radius: 4px;
    /* opacity: 0.27; */
    display: flex;
    justify-content: end;
    align-items: end;
    position: relative;
}

.capturedPlateBg {
  width: 600px;
  height: 250px;
  /* background: transparent #C6BCBC 0% 0% no-repeat padding-box; */
  background: #5f5e5e;
  border: 1px solid #C6BCBC;
  border-radius: 4px;
  /* opacity: 0.27; */
  display: flex;
  justify-content: end;
  align-items: end;
  position: relative;
}

  .spot {
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #00000089;
    border: 2px dotted red;
    transform: translate(-50%, -50%);
    font-size: 10px;
    text-align: center;
  }

  .zoomoutBtn {
    opacity: 1;
    letter-spacing: 0px;
    color: #F0050E;
    border: 1px solid #F0050E;
    background: none;
    font-size: 14px;
    font-family: "Open Sans";
    font-weight: 600;
    cursor: pointer;
  }

  .zoominBtn {
    opacity: 1;
    letter-spacing: 0px;
    color: #4f772d;
    border: 1px solid #4f772d;
    background: none;
    font-size: 14px;
    font-family: "Open Sans";
    font-weight: 600;
    cursor: pointer;
  }

  .addImageBtn {
    opacity: 1;
    color: #FFFFFF;
    border: 1px solid #588157;
    border-radius: 50px;
    background: #588157;
    font-size: 15px;
    font-family: "Open Sans";
    font-weight: 600;
    cursor: pointer;
    width: 30px;
    height: 30px;
  }

  .colonyCountWrap {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #C6BCBC;
    border-radius: 4px;
    opacity: 1;
    width: 15%;
  }

  .colonyCountText {
    font-size: 11px;
    line-height: 15px;
    font-family: 'Open Sans';
    font-weight: normal; 
    letter-spacing: 0px;
    color: #374355;
    opacity: 1;
  }

  .colonyCount {
    font-size: 18px;
    line-height: 24px;
    font-family: 'Open Sans';
    font-weight: 600; 
    letter-spacing: 0px;
    color: #374355;
    opacity: 1;

    outline: none;
    border: none;
    width: 100%;
  }

  .colonyRemarkTextArea {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid rgba(198, 188, 188, 1);
    border-radius: 4px;
    letter-spacing: 0px;
    font-size: 13px;
    line-height: 18px;
    font-family: 'Open Sans';
    font-weight: normal; 
    color: #374355;
    outline: none;
    resize: none;
    width: 93%;
    height: 100%;
    padding: 1.5%;
  }

 
  .colonyRemarkTextAreaLight::placeholder {
    color: rgba(55, 67, 85, 0.16);
  }

  .colonyRemarkTextAreaLight {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid rgba(198, 188, 188, 1);
    border-radius: 4px;
    letter-spacing: 0px;
    font-size: 13px;
    line-height: 18px;
    font-family: 'Open Sans';
    font-weight: normal; 
    color: rgba(55, 67, 85, 0.16);
    outline: none;
    resize: none;
    width: 93%;
    height: 100%;
    padding: 1.5%;
  }



  .colonyCountBottomSec {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    display: flex;
    width: 89%;
    justify-content: space-between;
    position: relative;
    /* bottom: 5; */
  }

  .labtestRightWrap {
    position: relative;
    min-height: 63vh;

  }
  .labtestInputWrap {
    box-shadow: 0px 3px 10px #0000002B;
  }

  .colonyCountBottomSec2 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #0000002B;
    opacity: 1;
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
  }

  .plateDetailHead {
    font: normal normal 600 14px/19px Open Sans;
    font-size: 14px;
    line-height: 19px;
    font-family: 'Open Sans';
    font-weight: 600; 
    letter-spacing: 0px;
    color: #05152B;
    opacity: 1;
  }

  .plateDetailText {
    font-size: 13px;
    line-height: 18px;
    font-family: 'Open Sans';
    font-weight: normal; 
    color: #374355;
    letter-spacing: 0px;
    opacity: 1;
  }

  .plateDetailBoldText {
    font-size: 13px;
    line-height: 18px;
    font-family: 'Open Sans';
    font-weight: 600; 
    color: #374355;
    letter-spacing: 0px;
    opacity: 1;
  }

.lightenText {
  opacity: 0.16;
}

.scanModal {
  height: 50vh;
  position: relative;
}

.bgQRCode {
   height: 100px;
   width: 100px;
   background-color: #5f5e5e;
}

.bgQRWrapp {
  position: absolute;
  padding: 4%;
  top: 35%;
  left: 35%;
  border: 1px solid orangered;

}