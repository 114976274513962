/* Calendar.css */

/* Google Font Import (Roboto font used) */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');


/* Calendar Container */
.calendar {
  font-family: 'Roboto', sans-serif;
  font-size: small;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 300px;
  max-width: 100%;
  margin: 0 auto;
  background-color: #fff;
}

/* Header Design */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #455570;
  color: white;
  padding: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

/* Navigation Buttons */
.header button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: white;
}

/* Days Grid */
.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

/* Individual Day Styles */
.day {
  padding: 5px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 2px 2px;
}

/* Hover Effect */
.day:hover {
  background-color: #7d8b97;
  color: white;
}

/* Selected Day Style */
.selected {
  background-color: #69727F;
  color: white;
  border-radius: 10px;
}
.week-days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    font-weight: bold;
    background-color: #f0f0f0;
    border-bottom: 1px solid #ccc;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  
  .week-days div {
    padding: 5px;
  }

  .rbc-calendar {
    height: 70vh !important;
  }