

.areaManagementHead {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #0000000F;
    opacity: 1;
    display: flex;
    justify-content: space-between;
}
.headText1 {
    font-size: 26px;
    line-height: 36px;
    font-family: 'Open Sans';
    font-weight: normal;
    letter-spacing: 0px;
    color: #06152B;
    opacity: 1;
    margin-top: 25px;
}

.boldText3 {
    letter-spacing: 0px;
    color: #455470;
    opacity: 1;
    font-size: 14px;
    line-height: 18px;
    font-family: 'Open Sans';
    font-weight: 600;
    padding-top: 15px;
  }
.boldText4 {
    font-size: 16px;
    line-height: 22px;
    font-family: 'Open Sans';
    font-weight: 600;
    letter-spacing: 0px;
    color: #06152B;
    opacity: 1;
  }
.areaManagementSearchSec {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 10px;
    zoom: .9;
}
.fullFilterShow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 0px;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 10px;
    /* zoom: .9; */
}
.userImgBg {
    width: 36px;
    height: 36px;
    border-radius: 50%;
}
.areaManagementSearch {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid rgba(6, 21, 43, 0.3);
    border-radius: 4px;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    width: 200px;
    height: 35px;
}
.headerText {
    font-size: 20px;
    line-height: 36px;
    font-family: 'Open Sans';
    font-weight: normal;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.headerTab {
    display: flex;
    justify-content: space-between;
    background: #36405D 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #0000000F;
    opacity: 1;
    color: #FFFFFF;
    height: 50px;
}

.searchInput {
    outline: none;
    border:none;
    width: 100%;
    height: 100%;
    color: #06152B;
    font-size: 13px;
    line-height: 20px;
    font-family: 'Open Sans';
    font-weight: normal;
    /* background-color: #0E4EC6; */
}
.areaManagementSearch ::placeholder {
    letter-spacing: 0px;
    color: #06152B;
    font-size: 13px;
    line-height: 20px;
    font-family: 'Open Sans';
    font-weight: normal;
}

.areaTypeSelect {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid rgba(6, 21, 43, 0.3);
    border-radius: 4px;
    opacity: 1;
    outline: none;
    padding: 0px 5px;
    letter-spacing: 0px;
    color:hsl(0, 0%, 20%);
    width: 150px;
    height: 35px;
    font-size: 13px;
    line-height: 20px;
    font-family: 'Open Sans';
    font-weight: normal;
}

.css-13cymwt-control, .css-t3ipsp-control {
    min-height: 34px !important;
    max-height: 34px !important;
    height: 35px !important;
    outline: none !important;
    text-align: left;
    /* background-color: #36405D !important; */
}

.css-1fdsijx-ValueContainer {
    overflow:visible !important;
    padding: 1% 0% !important;
    height: 35px !important;
}
.css-166bipr-Input, .css-qbdosj-Input, .css-1dimb5e-singleValue {
    margin-bottom: 12px !important;
    padding-bottom: 20px !important;
    min-height: 25px !important;
    max-height: 25px !important;
}

input#react-select-3-input{
    margin-bottom: 10px !important;
    min-height: 25px !important;
    max-height: 25px !important;

}

.css-1jqq78o-placeholder{
    margin-bottom: 10px !important;
}

.css-1u9des2-indicatorSeparator {
    margin-bottom: 0 !important;
    margin-top: 1px !important;
    height: 4vh !important;
}

/* .css-1wy0on6 {
    display: none !important;
    flex-shrink: 0 !important;
}


.css-151sz6c-indicatorContainer {
    padding-bottom: 0px !important;
    margin-bottom: 13px !important;
} */


.areaTypeSelect2 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #ECEBED !important;
    border-radius: 4px;
    opacity: 1;
    outline: none !important;
    box-shadow: none !important;
    letter-spacing: 0px;
    color: #06152B;
    width: 24vh; 
    font-size: 14px;
    line-height: 30px;
    font-family: 'Open Sans';
    font-weight: normal;
}

.css-3w2yfm-ValueContainer, .css-1dyz3mf {
    overflow: scroll !important;
    min-width: 350px !important;
    max-width: 400px !important;
    max-height: 30px !important;
    scrollbar-width: none !important;
}

.css-1fdsijx-ValueContainer {
    width: 150px !important;
}

/* .multiSelectRole {
    width: 200px;
    height: 35px;
} */

/* .multiSelectRole .css-13cymwt-control {
    overflow-y: scroll;
}


.multiSelectRole .css-3w2yfm-ValueContainer{
    overflow: auto !important;
    height: 30px !important;
    width: 300px;
}

.multiSelectRole .css-1p3m7a8-multiValue{
    width: 73px;
    font-size: 11px;
    font-weight: 500;
} */

.whiteBackground {
    width: 10vh;
    height: 5vh;
    background-color: #FFFFFF ;
}

.squareOrangeBtn2 {
    background: #FF7344 0% 0% no-repeat padding-box;
    box-shadow: 0px 7px 64px #00000012;
    opacity: 1;
    font-size: 13px;
    line-height: 19px;
    font-family: 'Open Sans';
    font-weight: 600;
    letter-spacing: 0px;
    color: #FFFFFF;
    /* outline: none; */
    border: none;
    padding: 0px 20px;
    border-radius: 5%;
  }

  .squareOrangeBtn3 {
    background: #FF7344 0% 0% no-repeat padding-box;
    box-shadow: 0px 7px 64px #00000012;
    font-size: 14px;
    line-height: 22px;
    font-family: 'Open Sans';
    font-weight: 600;
    letter-spacing: 0px;
    color: #FFFFFF;
    /* outline: none; */
    border: none;
    padding: 5px 20px;
  }
.squareOrangeBtn3Disabled {
    opacity: 0.3;
}
.redBtn {
    background: #d62828 0% 0% no-repeat padding-box;
    box-shadow: 0px 7px 64px #00000012;
    font-size: 14px;
    line-height: 22px;
    font-family: 'Open Sans';
    font-weight: 600;
    letter-spacing: 0px;
    color: #FFFFFF;
    outline: none;
    border: none;
    padding: 5px 20px; 
}
.greenBtn {
    background: #2a9d8f 0% 0% no-repeat padding-box;
    box-shadow: 0px 7px 64px #00000012;
    font-size: 14px;
    line-height: 22px;
    font-family: 'Open Sans';
    font-weight: 600;
    letter-spacing: 0px;
    color: #FFFFFF;
    outline: none;
    border: none;
    padding: 5px 20px; 
}

.areaManagementtableSec {
    background-color: #FF7344;
}
.logoutBtn {
    background-image: url("../../assets/images/logout.png");
    background-size: cover;
    height : 25px;
    width : 24px;
    border: none;
    outline: none;
    background-color: transparent;
    margin-top : 16px;
   
}

.headerDate {
    font-size: 12px;
    line-height: 18px;
    font-family: 'Open Sans';
    font-weight: 600;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    border-right: 1px solid grey;
}
.headerTime {
    font-size: 13px;
    line-height: 21px;
    font-family: 'Open Sans';
    font-weight: normal;
    letter-spacing: 2.86px;
    color: #FFFFFF;
    opacity: 1;
    margin-right: 15px;
}

.helpIcon {
    font-size: 16px;
    line-height: 18px;
    font-family: 'Open Sans';
    font-weight: 600;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    /* text-decoration: underline; */
    cursor: pointer;
}

.sessionLogoutText {
    font-size: 11px;
    line-height: 18px;
    font-family: 'Open Sans';
    font-weight: normal;
    letter-spacing: 0px;
    color: #181616;
    margin-bottom: 40px;
    padding-top: 6px;
}
.sessionLogoutTime {
    font-size: 12px;
    line-height: 18px;
    font-family: 'Open Sans';
    font-weight: 600;
    letter-spacing: 0px;
    color: #0E4EC6;
    opacity: 1;
}
.me-4.user-name {
    display: grid;
    justify-content: center;
    align-items: center;
}
.scrollable-list {
    max-height: 65vh;
    overflow: scroll;
}
.placeholder-style{
    font-family: 'Open Sans';
    font-size: 13px;
    width: 12rem;
    border: 1px solid #ccc;
    border-radius: 3px;
    outline: none;
}
input#incubationDurFromExp {
    margin-right: 60px;
}
input#labTestDurFromInc {
    margin-right: 50px;
}
input#AutoclaveDurFromLab {
    margin-right: 50px;
}
input#incubationTemparature {
    margin-top: 15px;
}
.filterLabel{
    color: black;
    margin-left: 15px;
    font-size: 12px;
    display: flex;
}
.filterLabelLeft{
    color: black;
    font-size: 12px;
    display: flex;
}
.table-left-head{
    margin-top: 20px;
}
.css-1nmdiq5-menu{
    text-align: left !important;
}