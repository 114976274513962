

 /* Styles for the loader container */
 .loader-container2 {
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Adjust as needed */
    /* background: transparent; */

    position: fixed;
    top: 0;
    left: 17%;
    width: 84%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
  }
  
  /* Styles for the loader */
  .loader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  /* Styles for the "please wait" text */
  p {
    margin-top: 20px;
  }
  
  /* Keyframes for the loader animation */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }