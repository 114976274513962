.workflow-design {
    font-family: Arial, sans-serif;
    padding: 20px;
    width: 99%;
    background-color: #f9f9f9;
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 9%;
  }
  
  .workflow-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px; 
    margin-bottom: 1rem; 
  }
  
  .form-group1 {
    flex: 1;
    margin-bottom: 15px;
  }
  
  .workflow-form label {
    display: block;
    margin-bottom: 5px;
    
  }
  
  .workflow-form input,
  .workflow-form textarea,
  .workflow-form select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .workflow-form textarea {
    height: 100px;
  }
  
  
  .save-button {
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    background: grey;
    background-size: 200% 100%;
    background-image: linear-gradient(to right, grey 50%, green 50%);
    transition: background-position 0.3s ease;
    font-weight: 300;
  }
  
  .save-button.grey {
    background-position: 0 0;
  }
  
  .save-button.halfGreen {
    background-position: 100% 0;
  }
  
  .save-button.fullGreen {
    background-position: 100% 0;
    background-color: #FF7344;
  }
  
  .save-button:hover {
    background-color: #45a049;
  }
  
  .dropdown-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    border-radius: 4px;
  }
  
  .process-label {
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .process-dropdown {
    padding: 8px;
    font-size: 14px;
    width: 400px;
  }
  
  .workflow-process {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .process-modal {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .process-modal h2 {
    margin-bottom: 20px;
  }
  
  .process-content {
    margin-bottom: 20px;
  }
  
  .form-group1 {
    margin-bottom: 15px;
  }
  
  .form-group1 .name {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group1 input, .form-group1 select, .form-group1 textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  .button-container {
    display: flex;
    justify-content: space-between;
  }
  
  .prev-button, .next-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .prev-button:disabled, .next-button:disabled {
    background-color: grey;
    cursor: not-allowed;
  }
  

  .wizard-container {
    display: flex;
    gap: 20px;
    margin: 20px;
  }
  
  .wizard-step {
    position: relative;
    width: 165px;
    height: 40px;
    background-color: lightgray;
    clip-path: polygon(0 0, 75% 0, 100% 50%, 75% 100%, 0 100%);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: 600;
    padding-left : 40px
  }
  
  .wizard-step span {
    margin-left: 10px; 
  }
  
  .highlight-green {
    background-color: green;
    color: white;
    font-weight: 600;
    padding-left : 40px
  }
  
  .home-screen-btn {
    margin-left: auto; 
  }
  
  .home-button {
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.3s;
    text-decoration: underline;
    font-size: 13px;
  }
  

  /* .button-container1 {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  } */

  .button-container1 {
    display: flex;
    justify-content: flex-end; /* Align buttons to the right */
    gap: 10px; /* Space between buttons */
    padding: 10px; /* Add some padding for spacing */
  }
  
  .save-button,
  .cancel-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 81px;
    height: 41px;
    border-radius: 47%;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-size: 15px;
  }
  
  .save-button {
    background-color: #4CAF50; /* Green background */
    color: white;
  }
  
  .save-button:hover {
    background-color: #45a049; /* Darker green on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Deeper shadow on hover */
  }
  
  .cancel-button {
    background-color: #f44336; /* Red background */
    color: white;
  }
  
  .cancel-button:hover {
    background-color: #e53935; /* Darker red on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Deeper shadow on hover */
  }
  
  .button-container1 button svg {
    font-size: 15px;
  }
  
  




  /* .workflow-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .left-tab {
    width: 40%;
    background-color: #f7eeee;
    padding: 20px;
    margin-right: 20px;
    margin-left: 20px;
    margin-top : 10px;
  }
  
  .right-tab {
    width: 70%;
  }
  
  .add-row-button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .add-row-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .add-row-button:hover {
    background-color: #0056b3;
  }
   */
/* Center the workflow container */
.workflow-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

/* Style for the left tab to center it within the container */
.left-tab {
  width: 300px;
  /* padding: 20px; */
  border-radius: 8px;
  text-align: center;
}

/* Style each row item */
.row-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  margin: 5px 0;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: #36405D 0% 0% no-repeat padding-box;
  color: white;
  height: 50px;
}

/* Style the add and save buttons */
.add-row-button-container,
.save-workflow-button-container {
  display: center;
  justify-content: space-between;
  margin-top: 20px;
}

.add-row-button,
.save-workflow-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  color: white;
}

.add-row-button {
  /* border-bottom: 2px solid #FF7344 !important; */
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  letter-spacing: 0px;
  color: #FF7344;
  opacity: 1;
  font-family: "Open Sans";
  font-size: 13px;
  line-height: 5px;
  font-weight: 600;
  background:none;
  margin: 0 2%;
  padding-bottom: 5px;
  outline: none;
}

.edit-icon {
  /* background-color: currentcolor; */
  cursor: pointer;
  font-size: 29px;
  border-radius: 27%;
  padding: 5px;
}
.edit-icon:hover {
  opacity: 0.8;
}
.process-name {
  flex-grow: 1;
  text-align: left;
  padding-left: 10px;
}
.edit-icon-container {
  display: flex;
  align-items: center;
  padding-right: 10px;
}

  /*dynamic workflow screen */
  .workflow-container1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    position: relative; 
  }
  
  .workflow-node {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 350px;
    height: 80px;
    background-color: #ffffff;
    border: 1px solid #000000;
    margin-bottom: 20px;
    position: relative;
    z-index: 1; 
  }
  
  .start-node {
    background-color: #b3e5fc; 
  }
  
  .process-node {
    background-color: #c8e6c9; 
  }
  
  .node-content {
    text-align: center;
    padding: 10px;
  }
 
  .workflow-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid #000000; 
    top: 80px; 
    left: calc(50% - 5px); 
    z-index: 0; 
  }
  
  .modal-dialog {
    max-width: 900px; 
  }
  
  .modal-body {
    word-wrap: break-word; 
  }
  
  .workflow-info {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 10px;
  }
  
  .info-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .info-label {
    background-color: #f0f0f0;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px 5px 0 0;
    width: 100px;
    text-align: center;
  }
  
  .info-value {
    background-color: #ffffff;
    padding: 10px;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 5px 5px;
    width: auto; 
    text-align: center;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
  }
  
  .label-value {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    white-space: nowrap;
  }
  .workflow-node {
    position: relative;
  }
  
  .workflow-arrow {
    position: absolute;
    width: 2px; 
    height: 20px; 
    background-color: #000000;
    bottom: -20px; 
    left: calc(50% - 1px);
  } 


  /*Plate Reconcilation css code*/
  .custom-table-reconcilation {
    width: 98%;
    border-collapse: collapse;
    border: 1px solid #ddd;
    margin-bottom: 20px;
  }
  
  .custom-table-reconcilation th,
  .custom-table-reconcilation td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .custom-table-reconcilation th {
    background-color: #6c757d;
    font-weight: 600;
    color: white
  }
  
 

  .custom-key {
    font-weight: bold;
  }

.custom-value {
    display: block;
    margin-top: 4px;
}


.process-tab {
  display: block;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  margin: 12px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 56px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}

.non-selected-tab {
  background-color: #f1f1f1;
}

.selected-tab {
  background: #36405D;
  color: white;
}

.custom-modal-workflow {
  width: 70% !important; /* Adjust the width as needed */
  max-width: 900px !important; /* Set a maximum width */
  height: 80% !important; /* Adjust the height as needed */
  max-height: 600px; /* Set a maximum height */
  margin: auto; /* Center the modal */
}

/* .custom-modal .modal-content {
  height: 100%; 
  border-radius: 10px; 
} */

/* .attribute-item {
  padding: 10px 0;
  display: flex;
  flex-direction: column; 
}

.attribute-key {
  font-weight: 300;
  color: #333;
  font-size: 12px;
  
}

.attribute-value {
  color: #555;
  opacity: 0.8;
}

.attribute-item:not(:last-child) {
  border-bottom: 1px solid #e0e0e0; 
  padding-bottom: 10px;
  margin-bottom: 10px;
  
} */


/* Hide the close (remove) icon for single select */
.custom-select .css-1xc3v61-indicatorContainer{
  display: none !important;
}

.custom-select .css-1wy0on6{
  display: none !important;
}

