.log-list-container {
    width: 50%;
    margin: 20px auto;
    border: 1px solid #ddd;
    height: 65vh; /* Adjust the height to your preference */
    overflow: hidden;
}

.log-list {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
}

.log-list th, .log-list td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.log-list th {
    background-color: #f2f2f2;
    text-align: center;
    position: sticky;
    top: 0;
    font-size: 16px;
}
.log-list th:hover {
    background-color: #f9f9fc;
}

.log-list tbody {
    display: block;
    height: calc(65vh - 40px); /* Subtract the height of the header row */
    overflow-y: auto;
    width: 101%;
}

.log-list tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.log-list thead, .log-list tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.download-button {
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
}

.download-button:hover {
    background-color: #45a049; 
}

/* Custom scrollbar styles */
.log-list tbody::-webkit-scrollbar {
    width: 12px;
}

.log-list tbody::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.log-list tbody::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
}

.log-list tbody::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.log-list tbody .tr:hover {
    background-color: antiquewhite;
}



/*Summary report css class starts here*/
.filter-component {
    display: flex;
    /* flex-direction: column; */
    /* align-items: center; */
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    /* max-width: 400px; */
    /* margin: 0 auto; */
    margin-top: 10px;
  }
  
  .filter-row {
    margin-bottom: 2px;
    margin-left: 3px;
    margin-right: 3px;
    width: 20%;
    z-index: 10;
  }
  
  .filter-label {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #333;
    margin-bottom: 5px;
  }
  
  .filter-select, .filter-input {
    padding: 7px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 5px;
  }
  
  .filter-button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #FF7344;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .filter-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .filter-button:not(:disabled):hover {
    background-color: #0056b3;
  }


/* plate summary reconciliation */

.key-value-pair-container {
    display: flex;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 2px; /* Rounded corners for the whole group */
    overflow: hidden; /* Prevents child content from overflowing */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    margin-top: 10px !important;
    margin-right: 1px !important;
    opacity: 1;
  }
  
  .key-value-item {
    margin-top : 5px;
    padding: 15px;
    text-align: center;
    flex-grow: 1; /* Ensures each column takes up equal space */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .key-value-item .key {
    font-weight: 400;
    color: #333;
    margin-bottom: 5px;
    font-size: 15px;
  }
  
  .key-value-item .value {
    font-weight: 600;
    font-size: 1rem;
    font-size: 30px;
  }
  
  .key-value-item:not(:last-child) {
    border-right: 1px solid #ddd; /* Separator line between columns */
  }
  
  .key-value-item:hover {
    background-color: #e9ecef;
  }
  
  /* Optional: Responsive design for smaller screens */
  @media (max-width: 768px) {
    .key-value-pair-container {
      flex-direction: column;
    }
    
    .key-value-item {
      border-right: none;
      border-bottom: 1px solid #ddd;
    }
  
    .key-value-item:not(:last-child) {
      border-bottom: 1px solid #ddd; /* Separator for stacked columns */
    }
  }
  
  .total-batches-value {
    color: #41A529; /* Tomato */
  }
  
  .total-plates-value {
    color: #1B7BAD; /* DodgerBlue */
  }
  
  .plates-used-value {
    color: #6A490B; /* LimeGreen */
  }
  
  .remaining-plates-value {
    color: #E0A811; /* DarkOrange */
  }

  .fullscreen-icon {  
    padding: 5px;
    border-radius: 50%;
    color: white;
  }

  .reconciliationSubTab {
    background: #D8DADD 0% 0% no-repeat padding-box;
    display: flex;
    margin-top: 5px;
    margin-right: 5px;
  }

  .reconciliationSubTab .active {
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    font-family: Open Sans;
    letter-spacing: 0px;
    color: #06152B;
    opacity: 1;
  }
  
  .subTableHead{
    /* background: silver 0% 0% no-repeat padding-box; */
    background: rgba(54, 64, 93, 0.11) 0% 0% no-repeat padding-box;
    color: "white" !important;
    font-size: 14px;
    /* opacity: 0.9; */
    margin-bottom: 10px;
    position: relative; 
    z-index: 1;
  }
  


  
  /* .microscope-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 16vh;
    margin-top: 5%;
    text-align: center;
    background-color: #f9f9f9;
  }
  
  .microscope-icon {
    font-size: 100px;
    color: #555; 
    animation: microscope-rotate 2s linear infinite, microscope-motion 1.5s ease-in-out infinite;
  }
   */

  .loading-text {
    margin-top: 20px;
    font-size: 14px; /* Set to 14px as requested */
    color: #333; /* Darker text for better readability */
    font-family: "Poppins", Arial, sans-serif; /* Modern and stylish font */
    font-weight: 600; /* Bold weight for emphasis */
    letter-spacing: 1px; /* Slight spacing for elegance */
    text-transform: uppercase; /* Uppercase for standout appearance */
    animation: text-pulse 1.5s ease-in-out infinite;
  }
  
  .emptyTableText {
    text-align: center;
    font-size: 14px; /* Matching font size for consistency */
    color: red;
    margin-top: 10%;
    font-family: "Arial", sans-serif;
  }
  
  /* Keyframes for rotating the microscope icon */
  @keyframes microscope-rotate {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(20deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  
  /* Keyframes for bouncing the microscope icon */
  @keyframes microscope-motion {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  /* Keyframes for text pulsing */
  @keyframes text-pulse {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    50% {
      opacity: 0.6;
      transform: scale(1.05);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  .empty-table-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 20vh;
    margin-top: 1%;
    text-align: center;
    padding : 10px;
  }
  
  .empty-text {
    margin-top: 10px;
    font-size: 14px; 
    font-family: "Poppins", Arial, sans-serif; 
    color: #555; 
    font-weight: 500; 
    text-transform: capitalize; 
  }
  




  
  .loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 16vh;
    margin-top: 5%;
    /* background-color: #f9f9f9; Light */
    text-align: center;
  }
  
  .spinner {
    border: 8px solid #f3f3f3; /* Light grey background color */
    border-top: 8px solid #555; /* Dark color for the spinning effect */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1.5s linear infinite; /* Make the spinner spin */
  }
  
  .loading-text {
    margin-top: 20px;
    font-size: 14px; /* Set font size */
    font-family: "Poppins", Arial, sans-serif; /* Modern and stylish font */
    color: #555; /* Neutral text color */
    font-weight: 500; /* Semi-bold for better readability */
    animation: text-pulse 1.5s ease-in-out infinite; /* Pulsing animation for text */
  }
  
  /* Spinning animation for the circular loader */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Text pulsing animation */
  @keyframes text-pulse {
    0%, 100% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.05);
      opacity: 0.6;
    }
  }
  
  /* Selected View Details */
.calendar-view-details {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  color: #444;
  margin-bottom: 1.0rem;
  text-align: center;
}

/* Date Picker Wrapper */
.custom-date-picker-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 6px 10px;
  background: linear-gradient(135deg, #f7f7f7, #e3e3e3);
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 5px 20px;
}

/* Label Styling */
.custom-date-picker-label {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

/* Input Field Styling */
.custom-date-picker-input {
  font-family: "Roboto", sans-serif;
  padding: 10px 12px;
  font-size: 14px;
  color: #555;
  border: 1px solid #ddd;
  border-radius: 6px;
  outline: none;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

/* Hover and Focus Effects */
.custom-date-picker-input:hover {
  border-color: #007bff;
  box-shadow: 0 3px 8px rgba(0, 123, 255, 0.2);
}

.custom-date-picker-input:focus {
  border-color: #0056b3;
  box-shadow: 0 3px 12px rgba(0, 86, 179, 0.3);
}

/* Wrapper Hover Effect */
.custom-date-picker-wrapper:hover {
  background: linear-gradient(135deg, #e3e3e3, #ffffff);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}
