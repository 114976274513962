
.sidebar {
    height: 100%;
    /* width: 230px; */
    width: 16.9%;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #0000000F;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px solid #FF7344;
    overflow-y: scroll;
    scrollbar-color: #FF7344;
    z-index: 10;
  }

  /* Styling the scrollbar itself (width and background) */
  .sidebar::-webkit-scrollbar {
    width: 8px; /* width of the entire scrollbar */
  }

  /* Styling the track (part the thumb travels in) */
  .sidebar::-webkit-scrollbar-track {
    background: #f1f1f1; /* color of the track */
  }

  /* Styling the thumb (the draggable part of the scrollbar) */
  .sidebar::-webkit-scrollbar-thumb {
    background-color: #f59f82; /* your color choice for the thumb */
    border-radius: 10px; /* rounded corners on the thumb */
    border: 2px solid #f1f1f1; /* creates padding around the thumb */
  }

  /* Optionally: style the thumb on hover */
  .sidebar::-webkit-scrollbar-thumb:hover {
    background: #E06337; /* darker shade of orange when hovering */
  }


  .sibebarActive {
    z-index: 1300;
  }

  .sidebar a {
    padding: 10px 20px;
    text-decoration: none;
    color: #374355;;
    font-size: 13px;
    line-height: 17px;
    font-family: 'Open Sans';
    font-weight: normal;
    display: block;
    height: 50px;
    display: flex;
    align-items: center;
    /* transition: 0.3s; */
  }
  
  .sidebar a:hover {
    /* background-color: #333; */
  }
  .sidebar .title {
    text-align: center;
    font-size: 30px;
    line-height: 22px;
    font-family: 'Poppins';
    font-weight: 500;
    letter-spacing: 0px;
    color: #343F5F;
    opacity: 1;
  }

  .sidebar .versionText {
    color: gray;
    font-family: 'Open Sans';
    font-weight: 800;
    text-align: center;
    padding-left: 25%;
    font-size: 12px;
  }
  
  .sidebar a.active {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000015;
    opacity: 1;
    color: #FF7344;
    border-right: 3px solid #FF7344;;
  }

  .sidebar a.groupDiv {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000015;
    opacity: 1;
    color: #ff4444;
    border-right: 3px solid #ff4444;;
  }

.sidebarIcon {
  font-size: 22px;
  fill: #77838F !important
}
.sidebarIconActive {
  font-size: 22px;
  fill: #FF7344 !important;
}
.sidebarIconBell{
  font-size: 30px;
  fill: #FF7344 !important;
  
}

/* .notificationBar {
  height: 100%;
  width: 400px;
  position: fixed;
  top: 0;
  left: 19.1%;
  padding-top: 20px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #0000000F;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #FF7344;
} */


.custom-modal .modal-dialog {
  margin-top: 0px !important;
  margin-left: 15.9% !important;
  margin-bottom: 0px !important;
  width: 27% !important;
}

.custom-modal .modal-content {
  border-radius: 0px !important;
  height: 100vh !important;
}

.notifiCard {
  display: flex;
  border-bottom: 1px solid rgb(226, 224, 224);
  margin-left: 20px;
  margin-top: 13px;

}
.notifiCardSel{
  display: flex;
  border-bottom: 1px solid rgb(226, 224, 224);
  background: rgba(253, 114, 67, 0.09);
  /* opacity: 0.09; */
}

.colTab {
  border-bottom: 1px solid rgb(226, 224, 224);
  display: flex;
  justify-content: start;
  /* margin-top: 2%; */
}

.colTabBtnSel {
  border-bottom: 2px solid #FF7344 !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  letter-spacing: 0px;
  color: #FF7344;
  opacity: 1;
  font-family: "Open Sans";
  font-size: 13px;
  line-height: 19px;
  font-weight: 600;
  background:none;
  margin: 0 2%;
  padding-bottom: 5px;
  outline: none;
}

.colTabBtn {
  border: none;
  letter-spacing: 0px;
  color: #272727;
  opacity: 1;
  font-family: "Open Sans";
  font-size: 13px;
  line-height: 19px;
  font-weight: 600;
  background:none;
  margin: 0 2%;
  outline: none;
}

.normalText1 {
  letter-spacing: 0px;
  color: #05152B;
  opacity: 1;
  font-size: 12px;
  line-height: 17px;
  font-family: 'Open Sans';
  font-weight: normal;
}
.normalText2 {
  letter-spacing: 0px;
  color: #05152B;
  opacity: 1;
  font-size: 14px;
  line-height: 19px;
  font-family: 'Open Sans';
  font-weight: normal;
}
.boldText2 {
  letter-spacing: 0px;
  color: #05152B;
  opacity: 1;
  font-size: 14px;
  line-height: 19px;
  font-family: 'Open Sans';
  font-weight: 600;
}

.lightText1{
  letter-spacing: 0px;
  color: #05152B;
  opacity: 0.6;
  font-size: 10px;
  line-height: 14px;
  font-family: 'Open Sans';
  font-weight: normal;
}

.squareOrangeBtn1 {
  background: #FF7344 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 64px #00000012;
  opacity: 1;
  font-size: 12px;
  line-height: 17px;
  font-family: 'Open Sans';
  font-weight: normal;
  letter-spacing: 0px;
  color: #FFFFFF;
  /* outline: none; */
  border: none;
  padding: 2% 4%;
}

.squareLightBtn1 {
  background: #FEFEFE 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 64px #00000012;
  border: 1px solid #B5A8A4;
  opacity: 1;
  font-size: 12px;
  line-height: 17px;
  font-family: 'Open Sans';
  font-weight: normal;
  letter-spacing: 0px;
  color: #898583;
  padding: 1.5% 4%;
}

.notifiUserBg {
  background: #F5F5F5 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 39px;
  height: 39px;
  border-radius: 50%;
}

.boldText1 {
  font-size: 15px;
  line-height: 22px;
  font-family: 'Open Sans';
  font-weight: 600;
  letter-spacing: 0px;
  color: #374355;
  opacity: 1;
}
.smallBoldText1 {
  font-size: 12px;
  line-height: 12px;
  font-family: 'Open Sans';
  font-weight: 600;
  letter-spacing: 0px;
  color: #374355;
  opacity: 1;
}

.notifyModalText {
  background: #F4F4F4 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  justify-content: space-between;
}

.custom-modal2 .modal-dialog {
  margin-top: 0px !important;
  margin-left: 43.5% !important;
  margin-bottom: 0px !important;
  width: 32% !important;

}

.custom-modal2 .modal-content {
  border-radius: 0px !important;
  height: 100vh !important;
  position: relative !important;
}

.filesBg {
  background: #E8E8E8 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  width: 66px;
  height: 66px;
}

.bottomRight {
  position: absolute;
  bottom: 2px;
  width: 100%;
}
.notifyContentWrap {
  width: 100%;
  background-color: #898583;
  display: flex;
}

.topContainer a {
  cursor: pointer;
}

.footerTab {
  display: flex;
  justify-content: center;
  background: #36405D 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #0000000F;
  opacity: 1;
  color: #FFFFFF;
  height: 30px;
  text-align: center;
  align-items: center;
}

.footer-text {
  margin-top : 15px;
  font-size: 12px;
  font-family: 'Open Sans';
  font-weight: normal;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.footer-link {
  color: #FFFFFF;
  text-decoration: none;
  font-weight: 600;
}

.footer-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.notifiCount {
  background: red;
  color: white;
  font-weight: bold;
  position: relative;
  right: 13px;
  font-size: 12px;
  padding: 0px 12px;
  border-radius: 55px;
  border: 1px solid red;
}

.notification {
  overflow: scroll;
  height: 650px;
}

/* Styling the scrollbar itself (width and background) */
.notification::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

/* Styling the track (part the thumb travels in) */
.notification::-webkit-scrollbar-track {
  background: #f1f1f1; /* color of the track */
}

/* Styling the thumb (the draggable part of the scrollbar) */
.notification::-webkit-scrollbar-thumb {
  background-color: #f59f82; /* your color choice for the thumb */
  border-radius: 10px; /* rounded corners on the thumb */
  border: 2px solid #f1f1f1; /* creates padding around the thumb */
}

/* Optionally: style the thumb on hover */
.notification::-webkit-scrollbar-thumb:hover {
  background: #E06337; /* darker shade of orange when hovering */
}



