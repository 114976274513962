.animated-button {
    transition: transform 0.2s, background-color 0.2s;
  }
  
  .animated-button:hover {
    transform: scale(1.1);
    background-color: #ffa500; /* Darker orange for hover */
    border-color: #ffa500;
  }
  
  .animated-button:active {
    transform: scale(0.9);
  }


  .notification-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    padding: 7px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .notification-image {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    border-radius: 5px;
    margin-bottom: 34px;
  }
  
  .notification-details {
    flex: 1;
  }
  
  .notification-name {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
    text-align: left; /* Ensuring text-align is properly typed */
  }
  
  .notification-date {
    font-size: 12px;
    color: #888;
    margin-bottom: 10px;
    text-align: left; /* Ensuring text-align is properly typed */
  }
  
  .buttons-container {
    display: flex;
    gap: 24px;
    width: 300px;
    height: 30px;
  }
  
  .button {
    flex: 1;
  }
  
  .view-button {
    background-color: #FF7344;
    color: white;
    border-radius: 4px;
    border: 0;
    flex: 1;
  }

  @keyframes ringing {
    0% { transform: rotate(0deg); }
    10% { transform: rotate(-10deg); }
    20% { transform: rotate(10deg); }
    30% { transform: rotate(-10deg); }
    40% { transform: rotate(10deg); }
    50% { transform: rotate(-10deg); }
    60% { transform: rotate(10deg); }
    70% { transform: rotate(-10deg); }
    80% { transform: rotate(10deg); }
    90% { transform: rotate(-5deg); }
    100% { transform: rotate(0deg); }
  }
  
  .ringing-icon {
    animation: ringing 1s ease-in-out infinite;
  }

  .alert-content{
    overflow-y: auto;
    max-height: 600px;
  }


  .top-center-modal .modal-dialog {
    margin-top: 0px !important;
    margin-right: 33% !important;
    margin-bottom: 0px !important;
  }
  
  .top-center-modal .modal-content {
    border-radius: 0px !important;
    height: 100vh !important;
  }
  
  .top-center-modal-md .modal-dialog {
    margin-top: 0px !important;
    margin-right: 20.5% !important;
    margin-bottom: 0px !important;
  }
  
  .top-center-modal-md .modal-content {
    border-radius: 0px !important;
    height: 100vh !important;
    width: 160%;
    /* position: relative;
      right: 5%; */
  }