

.signinBg {
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 0.30)), url("../assets/images/LoginPage.jpg"); */
    /* background-image:url("../assets/images/login.jpg"); */
    background-image: url("../assets/images/BG.png");
    background-color: #132026;
    background-size: cover;
    /* width: 200vh;
    height: 100vh;
    margin: 0;
    position: relative; */
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
}
.signinInputWrap {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    bottom: 0;
    right: 0;
    margin-right: 10%;
    margin-bottom: 3%;
    padding-bottom: 2%;
    /* background-color: rgb(66, 62, 62); */
}
.signinBrandArea{
    width: 79%;
    padding-right: 0%;
    margin-left: 20px;
}
.brandHead{
    text-align: right;
    font-size: 96px;
    line-height: 34px;
    font-family: 'Poppins';
    font-weight: 500px;
    letter-spacing: 2px;
    color: #FF7344;
    opacity: 1;
    margin-top: 4%;
    margin-left: 10px;
}

.brandDisc {
    text-align: left;
    font-family: 'Poppins';
    font-size: 25px;
    line-height: 30px;
    letter-spacing: 2px;
    color: #fff;
    opacity: 0.9;
    margin-left: 15px;
    margin-top: 30px;
}

.signinInputArea{
    /* background-color: bisque; */
    width: 20%;
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    padding: 2%;
    padding-left: 3%;
}

.formGroup {
    margin-bottom: 20px; /* Add some space between groups */
  }
  
  label {
    display: block; 
    margin-bottom: 5px;
    font-family: 'Open Sans';
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-weight: normal;
    text-wrap: nowrap;
  }

  input {
    height: 35px;
    outline: none;
  }

  input::-ms-reveal { display: none; }

.signinBtn1 {
    background: #FF7344 0% 0% no-repeat padding-box;
    box-shadow: 0px 7px 64px #00000012;
    opacity: 0.8;
    height: 30px;
    /* outline: none; */
    border: none;
    width: 50%;
    margin-left: 23%;
    color: #FFFFFF;
    margin-top: 5%;
    border-radius: 7px
}
.signinBtn2 {
    background: #FF7344 0% 0% no-repeat padding-box;
    box-shadow: 0px 7px 64px #00000012;
    opacity: 1;
    height: 35px;
    /* outline: none; */
    border: none;
    width: 50%;
    margin-left: 23%;
    color: #FFFFFF;
    margin-top: 5%;
    border-radius: 7px
}
.updateHead{
    text-align: right;
    /* font: normal medium 97px/67px "Poppins", sans-serif; */
    font-size: 56px;
    line-height: 31px;
    font-family: 'Poppins';
    font-weight: 500px;
    letter-spacing: 0;
    color: #000000;
    opacity: 1;
    margin-top:17% ;
    margin-bottom:10% ;
    white-space: nowrap;
}
.updateBtn2 {
    background: #FF7344 0% 0% no-repeat padding-box;
    box-shadow: 0px 7px 64px #00000012;
    opacity: 1;
    height: 30px;
    /* outline: none; */
    border: none;
    width: 60%;
    margin-left: 50%;
    color: #FFFFFF;
    margin-top: 2%;
    border-radius: 3px;
}
.updateBtn1 {
    background: #FF7344 0% 0% no-repeat padding-box;
    box-shadow: 0px 7px 64px #00000012;
    opacity: 0.4;
    height: 30px;
    /* outline: none; */
    border: none;
    width: 40%;
    margin-left: 50%;
    color: #FFFFFF;
    margin-top: 2%;
    border-radius: 3px;
}
.updatePassword-bg{
    background-image: linear-gradient(rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 0.30)), url("../assets/images/LoginPage.jpg");
    background-color: #132026;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    
}

.errorText {
    font-family: 'Open Sans';
    font-size: 12px;
    /* line-height: 20px; */
    /* letter-spacing: 0px; */
    color: red;
    text-align: start;
    padding-bottom: 0%;
    margin-bottom: 0%;
}

.loginInputArea{
    width: 450px; /* Adjust width as needed */
    height: 100vh; /* Adjust height as needed */
    background-image: linear-gradient(rgba(114, 26, 146, 0.3), rgba(176, 180, 189, 0.681));
    border: 1px solid; /* Base border */
    border-radius: 60px; /* Rounded corners */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Box shadow for 3D effect */
    padding: 20px; /* Adjust padding as needed */
    margin-top: 30px;
    margin-right: 80px;
    zoom: .9;
}


.UpdatePasswordInputArea{
  width: 450px; /* Adjust width as needed */
  height: 96vh; /* Adjust height as needed */
  background-image: linear-gradient(rgba(114, 26, 146, 0.3), rgba(176, 180, 189, 0.681));
  border: 1px solid; /* Base border */
  border-radius: 60px; /* Rounded corners */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Box shadow for 3D effect */
  padding: 20px; /* Adjust padding as needed */
  margin-top: 20px;
  margin-right: 80px;
}

.loginToText{
    color: white;
    font-size: 18px;
    font-family: sans-serif;
    font-weight: 600;
    margin-top: 15px;
    padding: 20px;
    opacity: 0.7;
    margin-left: 15px;
}

.SignupText{
  color: white;
  font-size: 18px;
  font-family: sans-serif;
  font-weight: 600;
  margin-top: 15px;
  opacity: 0.7;
  margin-left: 35px;
}

.input-login{
   margin-left : 30px;
   margin-top: 40px;
}

.input-password{
    margin-left : 30px;
    margin-top: 30px;
 }

.login-label{
    color: white;
    font-size: 14px;
}


.loginhexagon {
    height: 600px;
    width: 700px;
    margin-left: 10%;
    margin-top: 4%;
    zoom: .9;
}

  /* .gallery {
    --s: 190px; 
    --g: 10px;  
    display: grid;
    margin: calc(var(--s) + var(--g));
  }
  
  .gallery > img {
    grid-area: 1/1;
    width: var(--s);
    aspect-ratio: 0.866;
    object-fit: cover;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    transform: translate(var(--_x,0),var(--_y,0)) scale(var(--_t,1));
    cursor: pointer;
    filter: grayscale(10%);
    transition: .2s linear;
    z-index: 1;
    position: relative;
  }
  
  .gallery > img:hover {
    filter: grayscale(0);
    z-index: 2;
    --_t: 1.2;
  }
  
  .gallery > img:nth-child(1) {--_x: calc(-100% - var(--g))}
  .gallery > img:nth-child(7) {--_x: calc( 100% + var(--g))}
  .gallery > img:nth-child(3),
  .gallery > img:nth-child(5) {--_y: calc(-75% - .87*var(--g))}
  .gallery > img:nth-child(4),
  .gallery > img:nth-child(6) {--_y: calc( 75% + .87*var(--g))}
  .gallery > img:nth-child(3),
  .gallery > img:nth-child(4) {--_x: calc(-50% - .5*var(--g))}
  .gallery > img:nth-child(5), 
  .gallery > img:nth-child(6) {--_x: calc( 50% + .5*var(--g))} */
  



  .main-wrapper {
	width: 90%;
	/* max-width: 500px; */
	margin: -5rem auto;
	text-align: center;
}

.main-wrapper2 {
	width: 90%;
	/* max-width: 350px; */
	margin: 0 auto;
	text-align: center;
}

.badge {
	position: relative;
	margin: 1em 1em;
    width: 15em;
    height: 19.3em;
	border-radius: 11px;
	display: inline-block;
	top: 0;
	transition: all 0.3s ease;
  clip-path: polygon(50% 10%, 100% 30%, 100% 70%, 50% 90%, 0% 70%, 0% 30%);
  /* transform: rotate(-60deg); */
}

.badge:before,
.badge:after {
	position: absolute;
	width: inherit;
	height: inherit;
	border-radius: inherit;
	background: inherit;
	content: "";
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}

.badge:before {
	/* transform: rotate(-60deg); */
}

.badge:after {
	/* transform: rotate(60deg); */
}

.badge:hover {
	top: -4px;
}

.badge .circle {
	width: 60px;
	height: 60px;
	position: absolute;
	background: #fff;
	z-index: 10;
	border-radius: 50%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}

.badge .circle i.fa {
	font-size: 2em;
	margin-top: 8px;
}

.badge .font {
	display: inline-block;
	margin-top: 1em;
}

.badge .ribbon {
    position: absolute;
    border-radius: 5px;
    padding: 5px 5px 4px;
    width: 140px;
    z-index: 11;
    color: #fff;
    bottom: 11px;
    left: 45%;
    margin-left: -61px;
    margin-bottom: 50px;
    height: 20px;
    font-size: 12px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.27);
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
    background: linear-gradient(to bottom right, #555 0%, #333 100%);
    cursor: default;

}

.yellow {
	background: linear-gradient(to bottom right, #ffeb3b 0%, #fbc02d 100%);
	color: #ffb300;
}

.orange {
	background: linear-gradient(to bottom right, #ffc107 0%, #f57c00 100%);
	color: #f68401;
}

.pink {
	background: linear-gradient(to bottom right, #F48FB1 0%, #d81b60 100%);
	color: #dc306f;
}

.red {
	background: linear-gradient(to bottom right, #f4511e 0%, #b71c1c 100%);
	color: #c62828;
}

.purple {
	background: linear-gradient(to bottom right, #ab47bc 0%, #4527a0 100%);
	color: #7127a8;
}

.teal {
	background: linear-gradient(to bottom right, #4DB6AC 0%, #00796B 100%);
	color: #34a297;
}

.blue {
	background: linear-gradient(to bottom right, #4FC3F7 0%, #2196F3 100%);
	color: #259af3;
}

.blue-dark {
	background: linear-gradient(to bottom right, #1976D2 0%, #283593 100%);
	color: #1c68c5;
}

.green {
	background: linear-gradient(to bottom right, #cddc39 0%, #8bc34a 100%);
	color: #7cb342;
}

.green-dark {
	background: linear-gradient(to bottom right, #4CAF50 0%, #1B5E20 100%);
	color: #00944a;
}

.silver {
	color: #9e9e9e;
  /* background: #00796B; */
  background-image: url('https://static.toiimg.com/photo/85301544.cms'); /* Replace 'your-image-url.jpg' with the URL of your background image */
  background-size: cover; /* This will make the background image cover the entire div */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
}

.environmental {
	color: #9e9e9e;
  /* background: #00796B; */
  background-image: url('../assets/images/environmental.jpg'); /* Replace 'your-image-url.jpg' with the URL of your background image */
  background-size: cover; /* This will make the background image cover the entire div */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
}

.heading {
	background: #FF7344;
  /* background: #00796B; */
  /* background-image: url('../assets/images/heading.jpg'); */
  background-size: cover; /* This will make the background image cover the entire div */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
}

.clean_utilities {
	color: #9e9e9e;
  /* background: #00796B; */
  background-image: url('../assets/images/clean utilities.jpg'); /* Replace 'your-image-url.jpg' with the URL of your background image */
  background-size: cover; /* This will make the background image cover the entire div */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
}

.components {
	color: #9e9e9e;
  /* background: #00796B; */
  background-image: url('../assets/images/components.jpg'); /* Replace 'your-image-url.jpg' with the URL of your background image */
  background-size: cover; /* This will make the background image cover the entire div */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
}

.containerClosure{
	color: #9e9e9e;
  /* background: #00796B; */
  background-image: url('../assets/images/container-closure.jpg'); /* Replace 'your-image-url.jpg' with the URL of your background image */
  background-size: cover; /* This will make the background image cover the entire div */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
}

.surfaces{
    color: #9e9e9e;
    /* background: #00796B; */
    background-image: url('../assets/images/surfaces.jpg'); /* Replace 'your-image-url.jpg' with the URL of your background image */
    background-size: cover; /* This will make the background image cover the entire div */
    background-repeat: no-repeat; /* Prevent the background image from repeating */
}

.personnel{
    color: #9e9e9e;
    /* background: #00796B; */
    background-image: url('../assets/images/Personnel.jpg'); /* Replace 'your-image-url.jpg' with the URL of your background image */
    background-size: cover; /* This will make the background image cover the entire div */
    background-repeat: no-repeat; /* Prevent the background image from repeating */
}

.gold {
	background: linear-gradient(to bottom right, #e6ce6a 0%, #b7892b 100%);
	color: #b7892b;
}


.badge .login-text {
    position: absolute;
    border-radius: 5px;
    padding: 5px 5px 0px;
    width: 140px;
    z-index: 11;
    color: #fff;
    bottom: 25px;
    left: 45%;
    margin-left: -63px;
    margin-bottom: 70px;
    height: 81px;
    font-size: 20px;  
    cursor: default;
    line-height: 1.5;
}

.badge.components {
  z-index: -1;
}
